import axios from 'axios'
import JwtService from '../../services/JwtService'
import moment from 'moment'
import formJSON from '../../mocks/form.json'
import { createActivityLog } from './create'
const apiServerUrl = process.env.REACT_APP_STRAPI_URL

// UPDATE ACTIONS
export const updateUserAssignments = async (form, id) => {
  let body = {
    job_title: parseInt(form.job),
    office: parseInt(form.office),
    isApplied: false
  }
  if(form.new_supervisor !== ''){
    Object.assign(body, {new_supervisor: parseInt(form.new_supervisor), new_supervisor_email: null, new_supervisor_name: null, newSupervisorExist: true})
  } else {
    Object.assign(body, {new_supervisor: null, new_supervisor_email: form.new_supervisor_email, new_supervisor_name: form.new_supervisor_name, newSupervisorExist: false})
  }
  return axios.put(`${apiServerUrl}/api/user-assignments/${id}`, {
    data: body
  })
}

export const updateUserAssignmentsLogin = async (id) => {
  let body = {
    isApplied: true,
  }
  axios.put(`${apiServerUrl}/api/user-assignments/${id}`, {
    data: body
  })

}
export const updateAssignments = async (id, type, dataId) => {
    if (type === '1') {
      //supervisor
      return axios
        .get(
          `${apiServerUrl}/api/employee-supervisions?filters[employee][id][$eq]=${id}&filters[is_active][$eq]=true&populate=*`
        )
        .then(async (response) => {
          if (response.data.data.length > 0) {
            axios.get(`${apiServerUrl}/api/ppr-forms?filters[employee][id][$eq]=${id}&populate=*`)
            .then((res) => {
              if (res.data) {
                const mostRecentDate = res.data.data.reduce((maxDate, form) => {
                  const createdAt = new Date(form.attributes.createdAt);
                  return createdAt > maxDate ? createdAt : maxDate;
                }, new Date(0));
                const currentYear = new Date().getFullYear();
                const mostRecentYear = mostRecentDate.getFullYear();

                  const dataForMostRecentYear = mostRecentYear === currentYear ? res.data.data.filter((form) => {
                    const formYear = new Date(form.attributes.createdAt).getFullYear();
                    return formYear === mostRecentYear;
                  }) : null;
                  if(dataForMostRecentYear) {
                axios.put(`${apiServerUrl}/api/ppr-forms/${dataForMostRecentYear[0].id}`, {
                  data: {
                    manager: dataId,
                  },
                })
              }
              }
            })
            return axios
              .put(`${apiServerUrl}/api/employee-supervisions/${response.data.data[0].id}`, {
                data: {
                  is_active: true,
                  supervised_by: dataId,
                  date_started: new Date(),
                  history: [
                    ...(response.data.data[0].attributes.history
                      ? response.data.data[0].attributes.history
                      : []),
                    {
                      supervised_by:
                        response.data.data[0].attributes.supervised_by.data.attributes.full_name,
                      date_ended: new Date(),
                      date_started: response.data.data[0].attributes.date_started,
                    },
                  ],
                },
              })
              .then((res) => {
                return axios
                  .get(`${apiServerUrl}/api/employee-supervisions/${res.data.data.id}?populate=*`)
                  .then((result) => {
                    return {
                      date_ended: new Date(),
                      date_started: response.data.data[0].attributes.date_started,
                      supervised_by:
                        response.data.data[0].attributes.supervised_by.data.attributes.full_name,
                      new_supervised_by:
                        result.data.data.attributes.supervised_by.data.attributes.full_name,
                    }
                  })
              })
          } else {
            return axios
              .post(`${apiServerUrl}/api/employee-supervisions`, {
                data: {
                  is_active: true,
                  supervised_by: dataId,
                  date_started: new Date(),
                  history: [],
                  employee: id,
                },
              })
              .then((res) => {
                return axios
                  .get(`${apiServerUrl}/api/employee-supervisions/${res.data.data.id}?populate=*`)
                  .then((result) => {
                    return result.data.data.attributes.supervised_by.data.attributes.full_name
                  })
              })
          }
        })
    } else if (type === '2') {
      //office
      return axios
        .get(
          `${apiServerUrl}/api/office-assignments?filters[employee][id][$eq]=${id}&filters[is_active][$eq]=true&populate=*`
        )
        .then(async (response) => {
          console.log(response.data.data)
          if (response.data.data.length > 0) {
            return axios
              .put(`${apiServerUrl}/api/office-assignments/${response.data.data[0].id}`, {
                data: {
                  is_active: true,
                  office: dataId,
                  date_started: new Date(),
                  history: [
                    ...(response.data.data[0].attributes.history
                      ? response.data.data[0].attributes.history
                      : []),
                    {
                      office: response.data.data[0].attributes.office.data.attributes.name,
                      date_ended: new Date(),
                      date_started: response.data.data[0].attributes.date_started,
                    },
                  ],
                },
              })
              .then((res) => {
                return axios
                  .get(`${apiServerUrl}/api/office-assignments/${res.data.data.id}?populate=*`)
                  .then((result) => {
                    return {
                      date_ended: new Date(),
                      date_started: response.data.data[0].attributes.date_started,
                      office: response.data.data[0].attributes.office.data.attributes.name,
                      new_office: result.data.data.attributes.office.data.attributes.name,
                    }
                  })
              })
          } else {
            return axios
              .post(`${apiServerUrl}/api/office-assignments`, {
                data: {
                  is_active: true,
                  office: dataId,
                  date_started: new Date(),
                  history: [],
                  employee: id,
                },
              })
              .then((res) => {
                return axios
                  .get(`${apiServerUrl}/api/office-assignments/${res.data.data.id}?populate=*`)
                  .then((result) => {
                    return result.data.data.attributes.office.data.attributes.name
                  })
              })
          }
        })
    } else if (type === '3') {
      //office
      return axios
        .get(
          `${apiServerUrl}/api/job-assignments?filters[employee][id][$eq]=${id}&filters[is_active][$eq]=true&populate=*`
        )
        .then(async (response) => {
          if (response.data.data.length > 0) {
            return axios
              .put(`${apiServerUrl}/api/job-assignments/${response.data.data[0].id}`, {
                data: {
                  is_active: true,
                  job_title: dataId,
                  date_started: new Date(),
                  history: [
                    ...(response.data.data[0].attributes.history
                      ? response.data.data[0].attributes.history
                      : []),
                    {
                      job_title: response.data.data[0].attributes.job_title.data.attributes.name,
                      date_ended: new Date(),
                      date_started: response.data.data[0].attributes.date_started,
                    },
                  ],
                },
              })
              .then((res) => {
                return axios
                  .get(`${apiServerUrl}/api/job-assignments/${res.data.data.id}?populate=*`)
                  .then((result) => {
                    return {
                      date_ended: new Date(),
                      date_started: response.data.data[0].attributes.date_started,
                      job_title: response.data.data[0].attributes.job_title.data.attributes.name,
                      new_job_title: result.data.data.attributes.job_title.data.attributes.name,
                    }
                  })
              })
          } else {
            return axios
              .post(`${apiServerUrl}/api/job-assignments`, {
                data: {
                  is_active: true,
                  job_title: dataId,
                  date_started: new Date(),
                  history: [],
                  employee: id,
                },
              })
              .then((res) => {
                return axios
                  .get(`${apiServerUrl}/api/job-assignments/${res.data.data.id}?populate=*`)
                  .then((result) => {
                    return result.data.data.attributes.job_title.data.attributes.name
                  })
              })
          }
        })
    }
    else if (type === '4') {
      return axios
        .put(`${apiServerUrl}/api/users/${id}`, {
          blocked: dataId === '2' ? true : false, 
      })
    }
  }
  export const readNotification = async (id) => {
    return axios.put(`${apiServerUrl}/api/notifications/${id}`, {
      data: {
        read: true,
      },
    })
  }
  export const updatePPRFormManagers = async (employee, manager) => {
    let promises = []
    employee.forEach((id) => {
      promises.push(
        new Promise(async (resolve, reject) => {
          await axios
            .get(
              `${apiServerUrl}/api/ppr-forms?filters[employee][id][$eq]=${id}&populate=*&filters[manager][username][$null]=true`
            )
            .then((response) => {
              if (response.data.data.length > 0) {
                let new_promises = []
                response.data.data.forEach((data) => {
                  new_promises.push(
                    new Promise(async (resolve, reject) => {
                      await axios
                        .put(`${apiServerUrl}/api/ppr-forms/${data.id}`, {
                          data: {
                            manager: manager,
                          },
                        })
                        .then((response) => {
                          resolve()
                        })
                    })
                  )
                })
                Promise.all(new_promises).then(() => {
                  resolve()
                })
              }
            })
        })
      )
    })
    Promise.all(promises).then((response) => {
      return
    })
  }
  export const upsertEmployeeSupervision = async (employee, manager) => {
    let promises = []
    employee.forEach((id) => {
      promises.push(
        new Promise(async (resolve, reject) => {
          await axios
            .get(
              `${apiServerUrl}/api/employee-supervisions?filters[employee][id][$eq]=${id}&filters[supervised_by][id][$eq]=${manager}`
            )
            .then((response) => {
              if (response.data.data.length === 0) {
                return axios
                  .post(`${apiServerUrl}/api/employee-supervisions`, {
                    data: {
                      date_started: new Date(),
                      date_ended: new Date(),
                      is_active: true,
                      supervised_by: manager,
                      employee: id,
                      history: [],
                    },
                  })
                  .then((response) => {
                    resolve(response)
                  })
              }
            })
        })
      )
    })
    Promise.all(promises).then((response) => {
      return
    })
  }
  export const updateOfficeandTitle = async (id, data) => {
    await axios
      .get(`${apiServerUrl}/api/office-assignments?filters[employee][id][$eq]=${id}`)
      .then(async (response) => {
        if (response.data.data.length === 0) {
          await axios
            .get(`${apiServerUrl}/api/offices?filters[name][$eq]=${data.office}`)
            .then(async (response) => {
              if (response.data.data.length > 0) {
                await axios.post(`${apiServerUrl}/api/office-assignments`, {
                  data: {
                    office: response.data.data[0].id,
                    employee: id,
                    is_active: true,
                  },
                })
              }
            })
          await axios
            .get(`${apiServerUrl}/api/job-titles?filters[name][$eq]=${data.title}`)
            .then(async (response) => {
              if (response.data.data.length > 0) {
                await axios.post(`${apiServerUrl}/api/job-assignments`, {
                  data: {
                    office: response.data.data[0].id,
                    employee: id,
                    is_active: true,
                  },
                })
                // await axios.put(`${apiServerUrl}/api/users/${id}`, {
                //   job_titles: [response.data.data[0].id],
                // })
              }
            })
        }
      })
  }
  export const updateUserInfo = async (id, body) => {
    return axios.put(`${apiServerUrl}/api/users/${id}`, body).then((response) => {
      return response
    })
  }
  export const updatePPRFormOne = async (ppr, data, selectedPPRForm,type) => {
    if (ppr !== null) {
      let rows = Object.keys(data)
      const post = () => {
        return new Promise(async (resolve, reject) => {
          let promises = []
          rows.forEach(async (row) => {
            if (data[row]['id'] === null) {
              promises.push(
                new Promise(async (resolve, reject) => {
                  let body = {
                    ppr_form: ppr,
                    weight: parseInt(data[row]['A']),
                    duty_responsibility: data[row]['B'],
                    key_goals: data[row]['C'],
                    execution_results: data[row]['D'],
                    employee_rating: parseFloat(data[row]['E']),
                    supervisor_rating_date: data[row]['G'],
                  }
                  await axios.post(`${apiServerUrl}/api/ppr-form-ones`, {
                    data: body,
                  })
                  resolve()
                })
              )
            } else {
              promises.push(
                new Promise(async (resolve, reject) => {
                  let body = {
                    ppr_form: ppr,
                    weight: parseInt(data[row]['A']),
                    duty_responsibility: data[row]['B'],
                    key_goals: data[row]['C'],
                    execution_results: data[row]['D'],
                    employee_rating: parseFloat(data[row]['E']),
                    supervisor_rating_date: data[row]['G'],
                  }
                  await axios.put(`${apiServerUrl}/api/ppr-form-ones/${data[row]['id']}`, {
                    data: body,
                  })
                  resolve()
                })
              )
              if (selectedPPRForm.attributes.is_initial && type !== 'autosave') {
                await createActivityLog({
                  part: 'part 1',
                  action: 'update',
                  by: JwtService.getUserID(),
                  data: data,
                  owner: selectedPPRForm.attributes.employee.data,
                  ppr_form: ppr,
                  ppr_year: selectedPPRForm.attributes.date_review_start,
                })
              }
            }
          })
          Promise.all(promises).then(async () => {
            resolve('done')
          })
        })
      }
      await post()
    }
  }
  export const updatePPRFormOneSupervisor = async (ppr, data, selectedPPRForm, type) => {
    if (ppr !== null) {
      let rows = Object.keys(data)
      const post = () => {
        return new Promise(async (resolve, reject) => {
          let promises = []
          rows.forEach(async (row) => {
            if (data[row]['id'] === null) {
              promises.push(
                new Promise(async (resolve, reject) => {
                  let body = {
                    ppr_form: ppr,
                    supervisor_rating: parseFloat(data[row]['F']),
                    supervisor_rating_date: data[row]['G'],
                  }
                  await axios.post(`${apiServerUrl}/api/ppr-form-ones`, {
                    data: body,
                  })
                  resolve()
                })
              )
            } else {
              promises.push(
                new Promise(async (resolve, reject) => {
                  let body = {
                    ppr_form: ppr,
                    supervisor_rating: parseFloat(data[row]['F']),
                    supervisor_rating_date: data[row]['G'],
                  }
                  await axios.put(`${apiServerUrl}/api/ppr-form-ones/${data[row]['id']}`, {
                    data: body,
                  })
                  resolve()
                })
              )
              if (selectedPPRForm.attributes.is_initial && type !== 'autosave') {
                await createActivityLog({
                  part: 'part 1',
                  action: 'update',
                  by: JwtService.getUserID(),
                  data: data,
                  owner: selectedPPRForm.attributes.employee.data,
                  ppr_form: ppr,
                  ppr_year: selectedPPRForm.attributes.date_review_start,
                })
              }
            }
          })
          Promise.all(promises).then(async () => {
            resolve('done')
          })
        })
      }
      await post()
    }
  }
  export const updatePPRFormOneA = async (ppr, data, selectedPPRForm,type) => {
    if (ppr !== null) {
      let rows = Object.keys(data)
      return new Promise((resolve, reject) => {
        let promises = []
        rows.forEach((row) => {
          let item = formJSON[1].content.questions.filter((item) => item.key === row)[0]
          promises.push(
            new Promise(async (resolve, reject) => {
              let body = {
                ppr_form: ppr,
                task_description_title: item.header,
                task_description: item.description,
                rating_criteria: parseInt(data[row]),
              }
              let questionExist = await axios.get(
                `${apiServerUrl}/api/ppr-form-one-as?filters[task_description_title][$eq]=${item.header}&filters[ppr_form][id][$eq]=${ppr}`
              )
              if (questionExist.data.data.length > 0) {
                await axios.put(
                  `${apiServerUrl}/api/ppr-form-one-as/${questionExist.data.data[0].id}`,
                  {
                    data: body,
                  }
                )
              } else {
                await axios.post(`${apiServerUrl}/api/ppr-form-one-as`, {
                  data: body,
                })
              }
              resolve()
            })
          )
        })
        Promise.all(promises).then(async () => {
          if (selectedPPRForm.attributes.is_initial && type !== 'autosave') {
            await createActivityLog({
              part: 'part 1a',
              action: 'update',
              by: JwtService.getUserID(),
              data: data,
              owner: selectedPPRForm.attributes.employee.data,
              ppr_form: selectedPPRForm,
              ppr_year: selectedPPRForm.attributes.date_review_start,
            })
          }
          resolve('done')
        })
      })
    }
  }
  
  export const updatePPRFormTwo = async (ppr, data, supervisor, selectedPPRForm, part, type) => {
    if (ppr !== null) {
      let rows = Object.keys(data)
      return new Promise((resolve, reject) => {
        let promises = []
        rows.forEach((row) => {
          let item = formJSON[supervisor ? 8 : 2].content.questions.filter(
            (item) => item.key === row
          )[0]
          promises.push(
            new Promise(async (resolve, reject) => {
              if (
                (data[row]['employee'] !== '' || data[row]['supervisor'] !== '') &&
                data[row]['details'] !== ''
              ) {
                let body = {
                  ppr_form: ppr,
                  title: item.question,
                  description: item.description,
                  rating_employee: data[row]['employee'] ? parseInt(data[row]['employee']) : null,
                  remarks: data[row]['details'],
                  isby_supervisor: supervisor,
                }
                let questionExist = await axios.get(
                  `${apiServerUrl}/api/ppr-form-twos?filters[description][$eq]=${item.description}&filters[ppr_form][id][$eq]=${ppr}&filters[isby_supervisor][$eq]=${supervisor}`
                )
                if (questionExist.data.data.length > 0) {
                  await axios.put(
                    `${apiServerUrl}/api/ppr-form-twos/${questionExist.data.data[0].id}`,
                    {
                      data: body,
                    }
                  )
                } else {
                  await axios.post(`${apiServerUrl}/api/ppr-form-twos`, {
                    data: body,
                  })
                }
                resolve()
              } 
              else if(data[row]['details'] !== ''){
                {
                  let body = {
                    ppr_form: ppr,
                    title: item.question,
                    description: item.description,
                    rating_employee: data[row]['employee'] ? parseInt(data[row]['employee']) : null,
                    remarks: data[row]['details'],
                    isby_supervisor: supervisor,
                  }
                  let questionExist = await axios.get(
                    `${apiServerUrl}/api/ppr-form-twos?filters[description][$eq]=${item.description}&filters[ppr_form][id][$eq]=${ppr}&filters[isby_supervisor][$eq]=${supervisor}`
                  )
                  if (questionExist.data.data.length > 0) {
                    await axios.put(
                      `${apiServerUrl}/api/ppr-form-twos/${questionExist.data.data[0].id}`,
                      {
                        data: body,
                      }
                    )
                  } else {
                    await axios.post(`${apiServerUrl}/api/ppr-form-twos`, {
                      data: body,
                    })
                  }
                  resolve()
                }
              }
              else {
                resolve()
              }
            })
          )
        })
        Promise.all(promises).then(async () => {
          if (selectedPPRForm.attributes.is_initial && type !== 'autosave') {
            await createActivityLog({
              part: part,
              action: 'update',
              by: JwtService.getUserID(),
              data: data,
              owner: selectedPPRForm.attributes.employee.data,
              ppr_form: selectedPPRForm,
              ppr_year: selectedPPRForm.attributes.date_review_start,
            })
          }
          resolve('done')
        })
      })
    }
  }
  export const updatePPRFormTwoSupervisor = async (ppr, data, supervisor, selectedPPRForm, part,type) => {
    if (ppr !== null) {
      let rows = Object.keys(data)
      return new Promise((resolve, reject) => {
        let promises = []
        rows.forEach((row) => {
          let item = formJSON[supervisor ? 8 : 2].content.questions.filter(
            (item) => item.key === row
          )[0]
          promises.push(
            new Promise(async (resolve, reject) => {
              if (
                (data[row]['employee'] !== '' || data[row]['supervisor'] !== '') &&
                data[row]['details'] !== ''
              ) {
                let body = {
                  ppr_form: ppr,
                  title: item.question,
                  description: item.description,
                  rating_supervisor: data[row]['supervisor']
                    ? parseInt(data[row]['supervisor'])
                    : null,
                  isby_supervisor: supervisor,
                }
                let questionExist = await axios.get(
                  `${apiServerUrl}/api/ppr-form-twos?filters[description][$eq]=${item.description}&filters[ppr_form][id][$eq]=${ppr}&filters[isby_supervisor][$eq]=${supervisor}`
                )
                if (questionExist.data.data.length > 0) {
                  await axios.put(
                    `${apiServerUrl}/api/ppr-form-twos/${questionExist.data.data[0].id}`,
                    {
                      data: body,
                    }
                  )
                } else {
                  await axios.post(`${apiServerUrl}/api/ppr-form-twos`, {
                    data: body,
                  })
                }
                resolve()
              } else {
                resolve()
              }
            })
          )
        })
        Promise.all(promises).then(async () => {
          if (selectedPPRForm.attributes.is_initial && type !== 'autosave') {
            await createActivityLog({
              part: part,
              action: 'update',
              by: JwtService.getUserID(),
              data: data,
              owner: selectedPPRForm.attributes.employee.data,
              ppr_form: selectedPPRForm,
              ppr_year: selectedPPRForm.attributes.date_review_start,
            })
          }
          resolve('done')
        })
      })
    }
  }
  export const updatePPRFormFive = async (ppr, data, selectedPPRForm,type) => {
    if (ppr !== null) {
      let rows = Object.keys(data)
      const post = () => {
        return new Promise(async (resolve, reject) => {
          let promises = []
          rows.forEach((row) => {
            let body = {
              ppr_form: ppr,
              development_objectives: data[row]['A'],
              activities_required: data[row]['B'],
              timeframe: data[row]['C'],
            }
            if (data[row]['id'] === null) {
              promises.push(
                new Promise(async (resolve, reject) => {
                  await axios.post(`${apiServerUrl}/api/ppr-form-fives`, {
                    data: body,
                  })
                  resolve()
                })
              )
            } else {
              promises.push(
                new Promise(async (resolve, reject) => {
                  await axios.put(`${apiServerUrl}/api/ppr-form-fives/${data[row]['id']}`, {
                    data: body,
                  })
                  resolve()
                })
              )
            }
          })
          Promise.all(promises).then(async () => {
            if (selectedPPRForm.attributes.is_initial && type !== 'autosave') {
              await createActivityLog({
                part: 'part 5',
                action: 'update',
                by: JwtService.getUserID(),
                data: data,
                owner: selectedPPRForm.attributes.employee.data,
                ppr_form: ppr,
                ppr_year: selectedPPRForm.attributes.date_review_start,
              })
            }
            resolve('done')
          })
        })
      }
      await post()
    }
  }
  
  export const updatePPRFormSix = async (ppr, data, selectedPPRForm,type) => {
    if (ppr !== null) {
      let rows = Object.keys(data)
      const post = () => {
        return new Promise(async (resolve, reject) => {
          let promises = []
          rows.forEach((row) => {
            let body = {
              ppr_form: ppr,
              weight: data[row]['W'],
              duties_and_responsibilities: data[row]['A'],
              key_goals: data[row]['B'],
              timeframe: data[row]['C'],
            }
            if (data[row]['id'] === null) {
              promises.push(
                new Promise(async (resolve, reject) => {
                  await axios.post(`${apiServerUrl}/api/ppr-form-sixes`, {
                    data: body,
                  })
                  resolve()
                })
              )
            } else {
              promises.push(
                new Promise(async (resolve, reject) => {
                  await axios.put(`${apiServerUrl}/api/ppr-form-sixes/${data[row]['id']}`, {
                    data: body,
                  })
                  resolve()
                })
              )
            }
          })
          Promise.all(promises).then(async () => {
            if (selectedPPRForm.attributes.is_initial && type !== 'autosave') {
              await createActivityLog({
                part: 'part 6',
                action: 'update',
                by: JwtService.getUserID(),
                data: data,
                owner: selectedPPRForm.attributes.employee.data,
                ppr_form: ppr,
                ppr_year: selectedPPRForm.attributes.date_review_start,
              })
            }
            resolve('done')
          })
        })
      }
      await post()
    }
  }
  
  export const updatePPRFormByIdAdditionalComments = async (ppr, data) => {
    return new Promise(async (resolve, reject) => {
      let body = {
        additional_performance_comments: data,
      }
      await axios.put(`${apiServerUrl}/api/ppr-forms/${ppr}`, {
        data: body,
      })
      resolve()
    })
  }
  export const updatePPRFormByIdPerformanceComments = async (ppr, data, selectedPPRForm) => {
    return new Promise(async (resolve, reject) => {
      let body = {
        employee_comments: data.answers.employee,
        additional_performance_comments: data.answers.additional,
      }
      await axios
        .put(`${apiServerUrl}/api/ppr-forms/${ppr}`, {
          data: body,
        })
        .then(async (response) => {
          if (selectedPPRForm.attributes.is_initial) {
            await createActivityLog({
              part: 'part 4',
              action: 'update',
              by: JwtService.getUserID(),
              data: response.data.data,
              owner: selectedPPRForm.attributes.employee.data,
              ppr_form: selectedPPRForm,
              ppr_year: selectedPPRForm.attributes.date_review_start,
            })
          }
        })
      resolve()
    })
  }
  export const updatePPRFormByIdPerformanceCommentsSupervisor = async (ppr, data, selectedPPRForm) => {
    return new Promise(async (resolve, reject) => {
      let body = {
        supervisor_overall_summary_performance: data.answers.supervisor,
      }
      await axios
        .put(`${apiServerUrl}/api/ppr-forms/${ppr}`, {
          data: body,
        })
        .then(async (response) => {
          if (selectedPPRForm.attributes.is_initial) {
            await createActivityLog({
              part: 'part 4',
              action: 'update',
              by: JwtService.getUserID(),
              data: response.data.data,
              owner: selectedPPRForm.attributes.employee.data,
              ppr_form: selectedPPRForm,
              ppr_year: selectedPPRForm.attributes.date_review_start,
            })
          }
        })
      resolve()
    })
  }
  export const updatePPRFormByIdPerformanceRating = async (
    ppr,
    data,
    type,
    computedRating,
    selectedPPRForm
  ) => {
    return new Promise(async (resolve, reject) => {
      let body = {
        rating_level: computedRating,
      }
      if (type === 'Supervisor') Object.assign(body, {supervisor_rating_level: parseInt(data)})
      else if (type === 'President') Object.assign(body, {president_rating_level: parseInt(data)})
      await axios
        .put(`${apiServerUrl}/api/ppr-forms/${ppr}`, {
          data: body,
        })
        .then(async (response) => {
          if (selectedPPRForm.attributes.is_initial) {
            await createActivityLog({
              part: 'part 3',
              action: 'update',
              by: JwtService.getUserID(),
              data: response.data.data,
              owner: selectedPPRForm.attributes.employee.data,
              ppr_form: selectedPPRForm,
              ppr_year: selectedPPRForm.attributes.date_review_start,
            })
          }
        })
  
      resolve()
    })
  }
  export const updatePPRFormByIdSignatures = async (
    ppr,
    data,
    pprForm,
    currentPresident,
    currentUser
  ) => {
    if (ppr !== null) {
      let type = ''
      return new Promise(async (resolve, reject) => {
        let body = {}
        if (data.employee_approval_date) {
          Object.assign(body, {
            employee_approval_date: moment(data.employee_approval_date).format(),
            employee_approval: true,
          })
          type = 'employee'
        }
        if (data.manager_approval_date) {
          Object.assign(body, {
            manager_approval_date: moment(data.manager_approval_date).format(),
            manager_approval: true,
          })
          type = 'manager'
        }
        if (data.president_approval_date) {
          Object.assign(body, {
            president_approval_date: moment(data.president_approval_date).format(),
            president_approval: true,
          })
          type = 'ceo'
        }
        await axios
          .put(`${apiServerUrl}/api/ppr-forms/${ppr}`, {
            data: body,
          })
          .then(async (response) => {
            if (pprForm) {
              if (pprForm.attributes.is_initial) {
                await createActivityLog({
                  part: 'approvals',
                  action: 'approved',
                  by: JwtService.getUserID(),
                  data: response.data.data,
                  owner: pprForm.attributes.employee.data,
                  ppr_form: ppr,
                  ppr_year: pprForm.attributes.date_review_start,
                })
              }
              if (type === 'manager') {
                await axios.post(`${apiServerUrl}/api/notifications`, {
                  data: {
                    to: pprForm.attributes.employee.data,
                    subject: 'Approved by your supervisor',
                    ppr_form_owner: pprForm.attributes.employee.data,
                    email_content: `<b>Your PPR Form 2022 has been approved</b> by your supervisor, ${pprForm.attributes.manager.data.attributes.full_name}. <b>${currentPresident[0].full_name}, CEO & President, is currently reviewing your PPR Form</b>. Please note that you and your supervisor can still make changes.`,
                  },
                })
                await axios.post(`${apiServerUrl}/api/notifications`, {
                  data: {
                    to: currentPresident[0],
                    subject: `PPR Form 2022 Final Review - ${pprForm.attributes.employee.data.attributes.full_name}`,
                    ppr_form_owner: pprForm.attributes.employee.data,
                    email_content: `The  PPR Form 2022 of ${pprForm.attributes.employee.data.attributes.full_name} has been approved by its supervisor ${pprForm.attributes.manager.data.attributes.full_name}. <b>This PPR is subject for your review and final approval.</b>`,
                  },
                })
              } else if (type === 'ceo') {
                await axios.post(`${apiServerUrl}/api/notifications`, {
                  data: {
                    to: pprForm.attributes.employee.data,
                    subject: 'Approved by the CEO and President',
                    ppr_form_owner: pprForm.attributes.employee.data,
                    email_content: `<b>Your PPR Form 2022 has been approved by ${currentPresident[0].full_name} (CEO and President) and marked as COMPLETE.</b>`,
                  },
                })
                await axios.post(`${apiServerUrl}/api/notifications`, {
                  data: {
                    to: pprForm.attributes.manager.data,
                    subject: `PPR Form 2022 - ${pprForm.attributes.employee.data.attributes.full_name} (COMPLETE)`,
                    ppr_form_owner: pprForm.attributes.employee.data,
                    email_content: `<b>PPR FORM 2022 of ${pprForm.attributes.employee.data.attributes.full_name} has been approved by ${currentPresident[0].full_name} (CEO and President) and marked as COMPLETE.</b>`,
                  },
                })
              }
            }
          })
        resolve()
      })
    }
  }
  export const updatePPRFormById = async (ppr, data) => {
    if (ppr !== null) {
      return new Promise(async (resolve, reject) => {
        let body = {}
        let pprExist = await axios.get(`${apiServerUrl}/api/ppr-forms?filters[id][$eq]=${ppr}`)
        if (pprExist.data.data.length > 0) {
          if (data['Part 5']['answers']['employee']) {
            Object.assign(body, {employee_comments: data['Part 5']['answers']['employee']})
          }
          if (data['Part 5']['answers']['supervisor']) {
            Object.assign(body, {
              supervisor_overall_summary_performance: data['Part 5']['answers']['supervisor'],
            })
          }
          if (data['Part 5']['answers']['additional']) {
            Object.assign(body, {
              additional_performance_comments: data['Part 5']['answers']['additional'],
            })
          }
          await axios.put(`${apiServerUrl}/api/ppr-forms/${ppr}`, {
            data: body,
          })
        }
        resolve()
      })
    }
  }
  export const updatePPRForms = async (ppr, data, type, selectedPPRForm,year) => {
    let format = type
    if (ppr !== null) {
      if (data['Part 1']) {
        updatePPRFormOne(ppr, data['Part 1'], selectedPPRForm,format)
      }
      if (data['Part 2']) {
        updatePPRFormOneA(ppr, data['Part 2'], selectedPPRForm, format)
      }
      if (data['Part 3']) {
        updatePPRFormTwo(ppr, data['Part 3'], false, selectedPPRForm, 'part 2',format)
      }
      if (data['Part 9']) {
        updatePPRFormTwo(ppr, data['Part 9'], true, selectedPPRForm, 'part 2a',format)
      }
      if (data['Part 8']) {
        updatePPRFormFive(ppr, data['Part 8'], selectedPPRForm ,format)
      }
      if (data['Part 10']) {
        updatePPRFormSix(ppr, data['Part 10'], selectedPPRForm,format)
      }
      let new_obj = {
        is_just_created: false,
        employee_comments: data['Part 5']?.answers?.employee || '',
        additional_performance_comments: data['Part 5']?.answers?.additional || '',
      };
      if (type === 'draft') Object.assign(new_obj, {last_draft_update: new Date(), is_draft: true})
      if (type === 'initial') Object.assign(new_obj, {is_draft: false, is_initial: true, employee_approval: true, employee_approval_date: new Date()})
      return await axios
        .put(`${apiServerUrl}/api/ppr-forms/${ppr}`, {
          data: new_obj,
        })
        .then(async () => {
          if (type === 'initial') {
            await createActivityLog({
              part: null,
              action: 'submitted ppr form',
              by: selectedPPRForm.attributes.employee.data,
              data: selectedPPRForm,
              owner: selectedPPRForm.attributes.employee.data,
              ppr_form: selectedPPRForm,
              ppr_year: selectedPPRForm.attributes.date_review_start,
            })
            await axios.post(`${apiServerUrl}/api/notifications`, {
              data: {
                to: selectedPPRForm.attributes.employee.data,
                subject: `PPR Form ${year} - Submitted`,
                ppr_form_owner: selectedPPRForm.attributes.employee.data,
                email_content: `You have submitted your Initial PPR form ${year}. ${
                  selectedPPRForm.attributes.manager.data
                    ? selectedPPRForm.attributes.manager.data.attributes.full_name
                    : 'N/A'
                } is currently reviewing your submission. Please note that you can still make changes after the submission.`,
              },
            })
            if (selectedPPRForm.attributes.manager.data) {
              await axios.post(`${apiServerUrl}/api/notifications`, {
                data: {
                  to: selectedPPRForm.attributes.manager.data,
                  subject: `PPR Form ${year} Review - ${selectedPPRForm.attributes.employee.data.attributes.full_name}`,
                  ppr_form_owner: selectedPPRForm.attributes.employee.data,
                  email_content: `The PPR Form ${year} of ${selectedPPRForm.attributes.employee.data.attributes.full_name} <b>is subject for your review and approval.</b>`,
                },
              })
            }
          }
          if(type === 'autosave'){
              await createActivityLog({
                part: null,
                action: 'Autosaved ppr form',
                by: selectedPPRForm.attributes.employee.data,
                data: selectedPPRForm,
                owner: selectedPPRForm.attributes.employee.data,
                ppr_form: selectedPPRForm,
                ppr_year: selectedPPRForm.attributes.date_review_start,
              })
              await axios.post(`${apiServerUrl}/api/notifications`, {
                data: {
                  to: selectedPPRForm.attributes.employee.data,
                  subject: `PPR Form ${year} - Submitted`,
                  ppr_form_owner: selectedPPRForm.attributes.employee.data,
                  email_content: `You have submitted your Initial PPR form ${year}. ${
                    selectedPPRForm.attributes.manager.data
                      ? selectedPPRForm.attributes.manager.data.attributes.full_name
                      : 'N/A'
                  } is currently reviewing your submission. Please note that you can still make changes after the submission.`,
                },
              })
              if (selectedPPRForm.attributes.manager.data) {
                await axios.post(`${apiServerUrl}/api/notifications`, {
                  data: {
                    to: selectedPPRForm.attributes.manager.data,
                    subject: `PPR Form ${year} Review - ${selectedPPRForm.attributes.employee.data.attributes.full_name}`,
                    ppr_form_owner: selectedPPRForm.attributes.employee.data,
                    email_content: `The PPR Form ${year} of ${selectedPPRForm.attributes.employee.data.attributes.full_name} <b>is subject for your review and approval.</b>`,
                  },
                })  
            }
          }
          if(type === 'unsaved'){
            await createActivityLog({
              part: null,
              action: 'Unsaved ppr form',
              by: selectedPPRForm.attributes.employee.data,
              data: selectedPPRForm,
              owner: selectedPPRForm.attributes.employee.data,
              ppr_form: selectedPPRForm,
              ppr_year: selectedPPRForm.attributes.date_review_start,
            })
        }
        })
    }
  }
  export const updatePPRFormsSupervisor = async (ppr, data, type, selectedPPRForm,year) => {
    let format = type
    if (ppr !== null) {
      if (data['Part 1']) {
        updatePPRFormOneSupervisor(ppr, data['Part 1'], selectedPPRForm, format)
      }
      if (data['Part 3']) {
        updatePPRFormTwo(ppr, data['Part 3'], false, selectedPPRForm, 'part 2', format)
      }
      if (data['Part 9']) {
        updatePPRFormTwo(ppr, data['Part 9'], true, selectedPPRForm, 'part 2a', format)
      }
      if (data['Part 8']) {
        updatePPRFormFive(ppr, data['Part 8'], selectedPPRForm, format)
      }
      if (data['Part 10']) {
        updatePPRFormSix(ppr, data['Part 10'], selectedPPRForm, format)
      }
      let new_obj = {
        is_just_created: false,
        supervisor_overall_summary_performance: data['Part 5']['answers']['supervisor'],
      }
      if (type === 'draft') Object.assign(new_obj, {last_draft_update: new Date(), is_draft: true})
      if (type === 'initial') Object.assign(new_obj, {is_draft: false, is_initial: true, employee_approval: true, employee_approval_date: new Date()})
      return await axios
        .put(`${apiServerUrl}/api/ppr-forms/${ppr}`, {
          data: new_obj,
        })
        .then(async () => {
          if (type === 'initial') {
            await createActivityLog({
              part: null,
              action: 'submitted ppr form',
              by: selectedPPRForm.attributes.employee.data,
              data: selectedPPRForm,
              owner: selectedPPRForm.attributes.employee.data,
              ppr_form: selectedPPRForm,
              ppr_year: selectedPPRForm.attributes.date_review_start,
            })
            await axios.post(`${apiServerUrl}/api/notifications`, {
              data: {
                to: selectedPPRForm.attributes.employee.data,
                subject: `PPR Form ${year} - Submitted`,
                ppr_form_owner: selectedPPRForm.attributes.employee.data,
                email_content: `You have submitted your Initial PPR form ${year}. ${
                  selectedPPRForm.attributes.manager.data
                    ? selectedPPRForm.attributes.manager.data.attributes.full_name
                    : 'N/A'
                } is currently reviewing your submission. Please note that you can still make changes after the submission.`,
              },
            })
            if (selectedPPRForm.attributes.manager.data) {
              await axios.post(`${apiServerUrl}/api/notifications`, {
                data: {
                  to: selectedPPRForm.attributes.manager.data,
                  subject: `PPR Form ${year} Review - ${selectedPPRForm.attributes.employee.data.attributes.full_name}`,
                  ppr_form_owner: selectedPPRForm.attributes.employee.data,
                  email_content: `The PPR Form ${year} of ${selectedPPRForm.attributes.employee.data.attributes.full_name} <b>is subject for your review and approval.</b>`,
                },
              })
            }
          }
        })
    }
  }
  export const updatePPRFormYearByYear = async (year, bool) => {
    if (year !== null) {
      return axios
        .get(`${apiServerUrl}/api/ppr-form-years?filters[label][$eq]=${year}&populate=*`)
        .then(async (response) => {
          if (response.data.data.length > 0) {
            return axios
              .put(`${apiServerUrl}/api/ppr-form-years/${response.data.data[0].id}`, {
                data: {
                  is_active: bool,
                },
              })
              .then((response) => {
                // console.log(response)
              })
          }
        })
    }
  }
  export const updatePPRFormCompleteByYear = async (year, bool) => {
    if (year !== null) {
      return axios
        .get(`${apiServerUrl}/api/ppr-form-years?filters[label][$eq]=${year}&populate=*`)
        .then(async (response) => {
          if (response.data.data.length > 0) {
            return axios
              .put(`${apiServerUrl}/api/ppr-form-years/${response.data.data[0].id}`, {
                data: {
                  is_completed: bool,
                },
              })
              .then((response) => {
                // console.log(response)
              })
          }
        })
    }
  }
  export const updatePPRFormRatingByYear = async (year, bool) => {
    if (year !== null) {
      return axios
        .get(`${apiServerUrl}/api/ppr-form-years?filters[label][$eq]=${year}&populate=*`)
        .then(async (response) => {
          if (response.data.data.length > 0) {
            return axios
              .put(`${apiServerUrl}/api/ppr-form-years/${response.data.data[0].id}`, {
                data: {
                  start_rating: bool,
                },
              })
              .then((response) => {
                // console.log(response)
              })
          }
        })
    }
  }
  export const updateUserFromUserAssignment = async (id, data,currentPPR) => {
    console.log(data,'user')
    const userId = await axios.get(`${apiServerUrl}/api/users?filters[email][$eq]=${data.email}&populate=*`)
    const supervisor= await axios.get(
      `${apiServerUrl}/api/employee-supervisions?filters[employee][id][$eq]=${userId.data[0].id}&filters[is_active][$eq]=true&populate=*`
    )
    .then(async (response) => {
      if (response.data.data.length > 0) {
        return axios
          .put(`${apiServerUrl}/api/employee-supervisions/${response.data.data[0].id}`, {
            data: {
              is_active: true,
              supervised_by: data.new_supervisor.data.id,
              date_started: new Date(),
              history: [
                ...(response.data.data[0].attributes.history
                  ? response.data.data[0].attributes.history
                  : []),
                {
                  supervised_by:
                    response.data.data[0].attributes.supervised_by.data.attributes.full_name,
                  date_ended: new Date(),
                  date_started: response.data.data[0].attributes.date_started,
                },
              ],
            },
          })
          .then((res) => {
            return axios
              .get(`${apiServerUrl}/api/employee-supervisions/${res.data.data.id}?populate=*`)
              .then((result) => {
                return {
                  date_ended: new Date(),
                  date_started: response.data.data[0].attributes.date_started,
                  supervised_by:
                    response.data.data[0].attributes.supervised_by.data.attributes.full_name,
                  new_supervised_by:
                    result.data.data.attributes.supervised_by.data.attributes.full_name,
                }
              })
          })
        }
        else {
          return axios
            .post(`${apiServerUrl}/api/employee-supervisions`, {
              data: {
                is_active: true,
                supervised_by: data.new_supervisor.data.id,
                date_started: new Date(),
                history: [],
                employee: id,
              },
            })
            .then((res) => {
              return axios
                .get(`${apiServerUrl}/api/employee-supervisions/${res.data.data.id}?populate=*`)
                .then((result) => {
                  return result.data.data.attributes.supervised_by.data.attributes.full_name
                })
            })
        }
      }
    )
    const office= await axios.get(
      `${apiServerUrl}/api/office-assignments?filters[employee][id][$eq]=${userId.data[0].id}&filters[is_active][$eq]=true&populate=*`
    )
    .then(async (response) => {
      if (response.data.data.length > 0) {
        return axios
          .put(`${apiServerUrl}/api/office-assignments/${response.data.data[0].id}`, {
            data: {
              is_active: true,
              office: data.office.data.id,
              date_started: new Date(),
              history: [
                ...(response.data.data[0].attributes.history
                  ? response.data.data[0].attributes.history
                  : []),
                {
                  office: response.data.data[0].attributes.office.data.attributes.name,
                  date_ended: new Date(),
                  date_started: response.data.data[0].attributes.date_started,
                },
              ],
            },
          })
          .then((res) => {
            return axios
              .get(`${apiServerUrl}/api/office-assignments/${res.data.data.id}?populate=*`)
              .then((result) => {
                return {
                  date_ended: new Date(),
                  date_started: response.data.data[0].attributes.date_started,
                  office: response.data.data[0].attributes.office.data.attributes.name,
                  new_office: result.data.data.attributes.office.data.attributes.name,
                }
              })
          })
      } else {
        return axios
          .post(`${apiServerUrl}/api/office-assignments`, {
            data: {
              is_active: true,
              office: data.office.data.id,
              date_started: new Date(),
              history: [],
              employee: id,
            },
          })
          .then((res) => {
            return axios
              .get(`${apiServerUrl}/api/office-assignments/${res.data.data.id}?populate=*`)
              .then((result) => {
                return result.data.data.attributes.office.data.attributes.name
              })
          })
      }
    })
    const title= await axios
    .get(
      `${apiServerUrl}/api/job-assignments?filters[employee][id][$eq]=${userId.data[0].id}&filters[is_active][$eq]=true&populate=*`
    )
    .then(async (response) => {
      if (response.data.data.length > 0) {
        return axios
          .put(`${apiServerUrl}/api/job-assignments/${response.data.data[0].id}`, {
            data: {
              is_active: true,
              job_title: data.job_title.data.id,
              date_started: new Date(),
              history: [
                ...(response.data.data[0].attributes.history
                  ? response.data.data[0].attributes.history
                  : []),
                {
                  job_title: response.data.data[0].attributes.job_title.data.attributes.name,
                  date_ended: new Date(),
                  date_started: response.data.data[0].attributes.date_started,
                },
              ],
            },
          })
          .then((res) => {
            return axios
              .get(`${apiServerUrl}/api/job-assignments/${res.data.data.id}?populate=*`)
              .then((result) => {
                return {
                  date_ended: new Date(),
                  date_started: response.data.data[0].attributes.date_started,
                  job_title: response.data.data[0].attributes.job_title.data.attributes.name,
                  new_job_title: result.data.data.attributes.job_title.data.attributes.name,
                }
              })
          })
      } else {
        return axios
          .post(`${apiServerUrl}/api/job-assignments`, {
            data: {
              is_active: true,
              job_title: data.job_title.data.id,
              date_started: new Date(),
              history: [],
              employee: id,
            },
          })
          .then((res) => {
            return axios
              .get(`${apiServerUrl}/api/job-assignments/${res.data.data.id}?populate=*`)
              .then((result) => {
                return result.data.data.attributes.job_title.data.attributes.name
              })
          })
      }
    })
    const supervisorPPR= await axios.get(
      `${apiServerUrl}/api/ppr-forms?filters[employee][id][$eq]=${userId.data[0].id}&populate=*`
    ).then((res)=>{
      return axios.put(`${apiServerUrl}/api/ppr-forms/${res.data.data[0].id}`,{
        data:{
          manager: data.new_supervisor.data.id,
        }
    })
  })
  const userAssignment = await axios.put(`${apiServerUrl}/api/user-assignments/${id}`, {
    data: {
      isApplied: true,
    }
  })
    console.log(supervisor,'supervisor')
    console.log(office,'office')
    console.log(title,'title')
    console.log(supervisorPPR,'supervisorPPR')
    console.log(userAssignment,'userAssignment')
   
  }
  // UPDATE ACTIONS