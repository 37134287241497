// eslint-disable-next-line
import jwt_decode from 'jwt-decode'
import moment from 'moment' // require
import axios from 'axios' // require
moment().format()

const JWT_TOKEN_KEY = 'jwt_token' as string
const ACCESS_TOKEN_KEY = 'access_token' as string
const USER_ID = 'user_id' as string
const UNSAVED_CHANGES = 'unsaved_changes' as string
/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(JWT_TOKEN_KEY)
}
export const getAccessToken = (): string | null => {
  return window.localStorage.getItem(ACCESS_TOKEN_KEY)
}
export const getUserID = (): string | null => {
  return window.localStorage.getItem(USER_ID)
}
export const getUnsavedChanges = (): string | null => {
  return window.localStorage.getItem(UNSAVED_CHANGES)
}

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveJwT = (token: string): void => {
  window.localStorage.setItem(JWT_TOKEN_KEY, token)
}
export const saveAccessToken = (token: string): void => {
  window.localStorage.setItem(ACCESS_TOKEN_KEY, token)
}
export const saveUserID = (id: string): void => {
  window.localStorage.setItem(USER_ID, id)
}
export const saveUnsavedChanges = (answers: string): void => {
  window.localStorage.setItem(UNSAVED_CHANGES, answers)
}
/**
 * @description remove token form localStorage
 */
export const clearStorage = (): void => {
  window.localStorage.clear()
}
export const destroyUserID = (): void => {
  window.localStorage.removeItem(USER_ID)
}
export const destroyToken = (): void => {
  window.localStorage.removeItem(JWT_TOKEN_KEY)
}
export const destroyAPIToken = (): void => {
  window.localStorage.removeItem(ACCESS_TOKEN_KEY)
}
export const destroyUnsavedChanges = (): void => {
  window.localStorage.removeItem(UNSAVED_CHANGES)
}
export const VerifyTokenExpiration = (): boolean => {
  const accessToken = getToken();

  if (accessToken) {
    try {
      const data: any = jwt_decode(accessToken);
      const currentTime = Date.now() / 1000;
      return data.exp < currentTime;
    } catch (error) {
      console.error('Invalid token:', error);
      return true;
    }
    // const iatDate = new Date(data.iat * 1000);
    // const tenHoursAgo = new Date();
    // tenHoursAgo.setHours(tenHoursAgo.getHours() - 10);

    // return iatDate < tenHoursAgo;
  } else {
    return false;
  }
}
export const getUserInfo = () => {
  if (getAccessToken()) {
    const data: any = jwt_decode(getAccessToken() || '')
    return {
      firstname: data.user_data.name,
      image: data.user_data.image,
      full_name: data.user_data.name,
    }
  }
}
export const decode = (token: string) => {
  const data: any = jwt_decode(token)
  return data
}
// eslint-disable-next-line
export default {
  getUserID,
  saveUserID,
  destroyUserID,
  getUserInfo,
  getToken,
  getAccessToken,
  saveJwT,
  saveAccessToken,
  destroyToken,
  destroyAPIToken,
  VerifyTokenExpiration,
  clearStorage,
  decode,
  getUnsavedChanges,
  saveUnsavedChanges,
  destroyUnsavedChanges
}
