import {FC} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const Maintenance: FC = () => {
    return (
        <div className='d-flex flex-column flex-root'>
            <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
                {/* begin::Illustration */}
                <img
                    src={toAbsoluteUrl('/media/illustrations/sketchy-1/15.png')}
                    alt=''
                    className='mw-100 mb-10 h-lg-450px'
                />
                {/* end::Illustration */}
                {/* begin::Message */}
                <h1 className='fw-bold mb-10' style={{color: '#A3A3C7'}}>
                    Sorry for Inconvenience<br />
                </h1>
                {/* end::Message */}
                {/* begin::Message */}
                <h2 className='fw-bold mb-10' style={{color: '#A3A3C7'}}>
                    But we are undergoing Maintenance
                </h2>
                <h3 className='fw-bold mb-10' style={{color: '#A3A3C7'}}>
                    Please Try Again Later
                </h3>
                <h4 className='fw-bold' style={{color: '#A3A3C7'}}>
                    Please contact <a href='mailto:access@usasean.org' style={{color: '#5D78FF'}}>access@usasean.org</a> if you need help or encounter any problems.
                </h4>
                {/* end::Message */}
                {/* <Link to='/' className='btn btn-primary'>
                    Return Home
                </Link> */}
            </div>
        </div>
    )
}

export {Maintenance}
